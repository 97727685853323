import {
  api,
  createCrudService,
  responseToOptionsInContext
} from '@cargill/shared'
import _ from 'lodash'

const service = createCrudService('/api/centerAvailableCapacity', api)

service.processValidationContext = (component, ctx, meta) => {
  responseToOptionsInContext(ctx)
  const fieldsById = _.keyBy(meta.fields, (field) => field.id)
  fieldsById.centerInformation.options = ctx.centerInformationOptions
  fieldsById.receivingUsedCapacityUnity.options =
    ctx.receivingUsedCapacityUnityOptions
  fieldsById.azUsedCapacityUnity.options = ctx.azUsedCapacityUnityOptions
  fieldsById.expedUsedCapacityUnity.options = ctx.expedUsedCapacityUnityOptions
  fieldsById.receivingAvailableCapacityUnity.options =
    ctx.receivingAvailableCapacityUnityOptions
  fieldsById.azAvailableCapacityUnity.options =
    ctx.azAvailableCapacityUnityOptions
  fieldsById.expedAvailableCapacityUnity.options =
    ctx.expedAvailableCapacityUnityOptions
}

export default service
