<template>
  <div v-if="metadata != null">
    <cargill-crud-meta-view
      :service="crudService"
      :details="details"
      :metadata="metadata"
      translatePrefix="application.pages"
      :validation-schema="createValidationSchema"
    />
  </div>
</template>

<script>
import service from '../api/centerInformationService'
import centerAvailableCapacityService from '../api/centerAvailableCapacityService'
import { CargillCrudMetaView, helpers } from '@cargill/shared'
import { generateFieldsSchema } from '@brain/core'
import * as yup from 'yup'

export default {
  components: { CargillCrudMetaView },
  data() {
    return {
      metadata: null,
      crudService: service,
      createValidationSchema: Function
    }
  },
  methods: {
    async getMetaDetails() {
      return await Promise.all([this.getCenterAvailableCapacityMeta()])
    },
    async getCenterAvailableCapacityMeta() {
      const centerAvailableCapacityMeta =
        await centerAvailableCapacityService.getMetaWithValidation(this)
      helpers.processMetaDetails(
        centerAvailableCapacityMeta,
        'centerAvailableCapacity',
        'centerInformationId',
        'centerInformation',
        centerAvailableCapacityService
      )
      return centerAvailableCapacityMeta
    }
  },
  created() {
    const getMeta = async () => {
      let [meta, metaDetails] = await Promise.all([
        service.getMetaWithValidation(this),
        this.getMetaDetails()
      ])

      this.createValidationSchema = (meta, translate) => {
        const validationSchema = generateFieldsSchema(meta, translate)
        const receivingCapacitySchema = validationSchema['receivingCapacity']
        validationSchema['receivingCapacity'] = receivingCapacitySchema.when(
          ['centerType'],
          {
            is: (centerType) => centerType?.value !== 1,
            then: receivingCapacitySchema
              .required()
              .label(translate('receivingCapacity')),
            otherwise: receivingCapacitySchema
          }
        )

        return yup.object().shape(validationSchema)
      }

      meta.details = metaDetails
      return meta
    }

    getMeta().then((meta) => {
      this.details = helpers.createDetailsObject(meta)
      this.metadata = meta
    })
  },
  mounted() {}
}
</script>
